import React from 'react';
import './Zen.css';  // Import the CSS file

function Zen() {
  return (
    <div className="zen-container">
      <div className="zen-content">
        <img src={process.env.PUBLIC_URL + '/images/zen2.png'} alt="Zen Movement" className="zen-logo" />
        <h1 className="zen-title">Welcome to Zen Mode</h1>
        <p className="zen-subtitle">Discover your eternal youth</p>
      </div>
    </div>
  );
}

export default Zen;
