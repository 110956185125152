import logo from '../logo.svg';
import React from 'react';

function Home() {
  return (
    <div>
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            The future belongs to the bold.
          </p>
          <p>
          <iframe title="forex" frameborder="0" width="220" height="140" src="https://www.mql5.com/en/signals/widget/signal/6uer"></iframe>
          </p>
          <p>
            Learn to trade and be financially free for ever
          </p>
        </header>

    </div>
  );
}

export default Home;
