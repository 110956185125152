import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Zen from './components/Zen';  // Assuming you create a Zen component
import Home from './components/Home';  // Assuming you create a Home component

function App() {
  return (
    <Router>
    <Routes>
      <Route path="/" element={<Home />} />  {/* Assuming you have a Home component */}
      <Route path="/zen" element={<Zen />} />
    </Routes>
  </Router>
  );
}

export default App;
